import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  isSearchOpen: boolean;
};

const InputWrapper = styled('div')<Props>(
  ({ isSearchOpen = false, theme }) => ({
    paddingRight: '1.5rem',
    position: 'relative',
    width: '100%',

    ...(isSearchOpen
      ? {
          [mediaQueryBuilder(theme.mediaQueries.max.width['1024'])]: {
            width: '50%',
          },

          [mediaQueryBuilder(theme.mediaQueries.max.width['599'])]: {
            width: '100%',
          },
        }
      : {}),
  }),
);

export default InputWrapper;
