import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  isSearchOpen: boolean;
};

const SearchForm = styled('form')<Props>(({ isSearchOpen = false, theme }) => ({
  color: theme.colors.gray['600'],
  textAlign: 'center',
  verticalAlign: 'middle',
  width: '21rem',

  ...(isSearchOpen
    ? {
        alignItems: 'center',
        bottom: 0,
        display: 'flex',
        justifyContent: 'flex-end',
        width: 'auto',

        [mediaQueryBuilder(theme.mediaQueries.min.width['768'])]: {
          justifyContent: 'center',
          left: 0,
          margin: 'auto',
          position: 'absolute',
          right: 0,
          top: 0,
          width: '50%',
        },

        [mediaQueryBuilder(theme.mediaQueries.max.width['599'])]: {
          width: '100%',

          '.search-filter': {
            width: '20rem',
          },
        },
      }
    : {}),
}));

export default SearchForm;
