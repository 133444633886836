import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  pushDown?: boolean;
  isSearchOpen: boolean;
};

const HeaderFull = styled('div')<Props>(
  ({ pushDown = false, isSearchOpen = false, theme }) => ({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',

    ...(pushDown
      ? {
          maxWidth: '142rem',
          padding: '0 1.5rem',
        }
      : {}),

    [mediaQueryBuilder(theme.mediaQueries.max.width['599'])]: {
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'space-around',
    },

    ...(isSearchOpen
      ? {}
      : {
          [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
            display: 'none',
          },
        }),
  }),
);

export default HeaderFull;
